import React from 'react';

import Layout from '../components/layout';
import Seo from '../components/Seo';

const TermsOfUsePage = ({ location }) => (
  <Layout page="cookies" location={location}>
    <Seo
      manualTags={{
        seoTitle: 'Terms of use',
        seoDescription: '',
      }}
    />
    <section className="aq-feature-bg pt-8 pb-8 bg-grey">
      <div className="container-fluid">
        <div className="component-wrap">
          <div className="row">
            <div className="col">
              <h1 className="h1-two-line-v2 green mb-15 mt-6 text-center">
                <span>Terms of use</span>
              </h1>
              <br />
              <br />
              <p>
                <strong>
                  This page (together with the documents referred to on it)
                  tells you the terms of use on which you may make use of our
                  website www.southeastwater.co.uk (our site).
                </strong>
              </p>
              <p>
                <strong>
                  Please read these terms of use carefully before you start to
                  use the site. By using our site, you indicate that you accept
                  these terms of use and that you agree to abide by them. If you
                  do not agree to these terms of use, please refrain from using
                  our site.
                </strong>
              </p>
              <br />
              <div>
                <h4>
                  <strong className="text-teal">Information about us</strong>
                </h4>
                <p>
                  www.southeastwater.co.uk is a site operated by South East
                  Water Limited ("We"). We are registered in England and Wales
                  under company number 02679874 and have our registered office
                  at Rocfort Road, Snodland, Kent, ME6 5AH.
                </p>
                <p>We are regulated by Ofwat.</p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">Accessing our site</h4>
                <p>
                  Access to our site is permitted on a temporary basis, and we
                  reserve the right to withdraw or amend the service we provide
                  on our site without notice (see below). We will not be liable
                  if for any reason our site is unavailable at any time or for
                  any period.
                </p>
                <p>
                  You agree to use this site only for lawful purposes and in a
                  manner which does not infringe the rights or inhibit the use
                  of the website by any third party.
                </p>
                <p>
                  You are responsible for making all arrangements necessary for
                  you to have access to our site. You are also responsible for
                  ensuring that all persons who access our site through your
                  internet connection are aware of these terms, and that they
                  comply with them.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">Intellectual property rights</h4>
                <p>
                  We are the owner or the licensee of all intellectual property
                  rights in our site, and in the material published on it. Those
                  works are protected by copyright laws and treaties around the
                  world. All such rights are reserved.
                </p>
                <p>
                  You may print off one copy, and may download extracts, of any
                  page(s) from our site for your personal reference.
                </p>
                <p>
                  You must not modify the paper or digital copies of any
                  materials you have printed off or downloaded in any way, and
                  you must not use any illustrations, photographs, video or
                  audio sequences or any graphics separately from any
                  accompanying text.
                </p>
                <p>
                  Our status (and that of any identified contributors) as the
                  authors of material on our site must always be acknowledged.
                </p>
                <p>
                  You must not use any part of the materials on our site for
                  commercial purposes without obtaining a licence to do so from
                  us or our licensors.
                </p>
                <p>
                  If you print off, copy or download any part of our site in
                  breach of these terms of use, your right to use our site will
                  cease immediately and you must, at our option, return or
                  destroy any copies of the materials you have made.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">Our site changes regularly</h4>
                <p>
                  We aim to update our site regularly, and may change the
                  content at any time. If the need arises, we may suspend access
                  to our site, or close it indefinitely. Whilst we aim to keep
                  our site as up to date as possible, it is possible that some
                  material on our site may be out of date at any given time, and
                  we will do our best to update this as soon as possible.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">
                  <strong>Our liability</strong>
                </h4>
                <p>
                  The material displayed on our site is provided without any
                  guarantees, conditions or warranties as to its accuracy. To
                  the extent permitted by law, we, other members of our group of
                  companies and third parties connected to us hereby expressly
                  exclude:
                </p>
                <p>
                  All conditions, warranties and other terms which might
                  otherwise be implied by statute, common law or the law of
                  equity.
                </p>
                <p>
                  Any liability for any direct, indirect or consequential loss
                  or damage incurred by any user in connection with our site or
                  in connection with the use, inability to use, or results of
                  the use of our site, any websites linked to it and any
                  materials posted on it, including, without limitation any
                  liability for:
                </p>
                <ul>
                  <li>
                    loss of income or revenue; loss of business; loss of profits
                    or contracts; loss of anticipated savings; loss of data;
                    loss of goodwill; wasted management or office time; and for
                    any other loss or damage of any kind, however arising and
                    whether caused by tort (including negligence), breach of
                    contract or otherwise, even if foreseeable, provided that
                    this condition shall not prevent claims for loss of or
                    damage to your tangible property or any other claims for
                    direct financial loss that are not excluded by any of the
                    categories set out above.
                  </li>
                </ul>
                <p>
                  This does not affect our liability for death or personal
                  injury arising from our negligence, nor our liability for
                  fraudulent misrepresentation or misrepresentation as to a
                  fundamental matter, nor any other liability which cannot be
                  excluded or limited under applicable law.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">
                  Information about you and your visits to o
                  <strong>ur site</strong>
                </h4>
                <p>
                  We process information about you in accordance with our
                  personal information policy. By using our site, you consent to
                  such processing and you warrant that all data provided by you
                  is accurate.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">
                  <strong>Viruses, hacking and other offences</strong>
                </h4>
                <p>
                  You must not misuse our site by knowingly introducing viruses,
                  trojans, worms, logic bombs or other material which is
                  malicious or technologically harmful. You must not attempt to
                  gain unauthorised access to our site, the server on which our
                  site is stored or any server, computer or database connected
                  to our site. You must not attack our site via a
                  denial-of-service attack or a distributed denial-of service
                  attack.
                </p>
                <p>
                  By breaching this provision, you would commit a criminal
                  offence under the Computer Misuse Act 1990. We will report any
                  such breach to the relevant law enforcement authorities and we
                  will co-operate with those authorities by disclosing your
                  identity to them. In the event of such a breach, your right to
                  use our site will cease immediately.
                </p>
                <p>
                  We will not be liable for any loss or damage caused by a
                  distributed denial-of-service attack, viruses or other
                  technologically harmful material that may infect your computer
                  equipment, computer programs, data or other proprietary
                  material due to your use of our site or to your downloading of
                  any material posted on it, or on any website linked to it.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">
                  <strong>Linking to our site</strong>
                </h4>
                <p>
                  You may link to our site, provided you do so in a way that is
                  fair and legal and does not damage our reputation or take
                  advantage of it, but you must not establish a link in such a
                  way as to suggest any form of association, approval or
                  endorsement on our part where none exists.
                </p>
                <p>
                  You must not establish a link from any website that is not
                  owned by you, unless you have permission, for example if you
                  are a member of a forum site.
                </p>
                <p>
                  Our site must not be framed on any other site. We reserve the
                  right to withdraw linking permission without notice.
                  <br />
                  <br />
                  If you wish to make any use of material on our site other than
                  that set out above, please address your request to{' '}
                  <a
                    href="mailto:SEWCommunications@southeastwater.co.uk"
                    className=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    SEWCommunications@southeastwater.co.uk
                  </a>
                  .
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">Links from our site</h4>
                <p>
                  Where our site contains links to other sites and resources
                  provided by third parties, these links are provided for your
                  information only. We have no control over the contents of
                  those sites or resources, and accept no responsibility for
                  them or for any loss or damage that may arise from your use of
                  them.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">
                  <strong>Jurisdiction and applicable law</strong>
                </h4>
                <p>
                  The English courts will have exclusive jurisdiction over any
                  claim arising from, or related to, a visit to our site
                  although we retain the right to bring proceedings against you
                  for breach of these conditions in your country of residence or
                  any other relevant country.
                </p>
                <p>
                  These terms of use and any dispute or claim arising out of or
                  in connection with them or their subject matter or formation
                  (including non-contractual disputes or claims) shall be
                  governed by and construed in accordance with the law of
                  England and Wales.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">Trade marks</h4>
                <p>
                  South East Water’s name is a registered UK trade mark under
                  registration number 2383692.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">
                  <strong>Variations</strong>
                </h4>
                <p>
                  We may revise these terms of use at any time by amending this
                  page. You are expected to check this page from time to time to
                  take notice of any changes we made, as they are binding on
                  you.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">
                  <strong>Your concerns</strong>
                </h4>
                <p>
                  If you have any concerns about material which appears on our
                  site, please contact{' '}
                  <a
                    href="mailto:SEWCommunications@southeastwater.co.uk"
                    className=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>SEWCommunications@southeastwater.co.uk</strong>
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default TermsOfUsePage;
